import axios from 'axios'

let languageObj = JSON.parse(localStorage.getItem('language'));
let language = 'ru';
if (languageObj != null)
    language = languageObj.code;

const instance = axios.create({
    baseURL: "https://housechat.el-turk.com",
    headers: { 'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('identity'))?.data.token, 'Accept-Language': language }
})

instance.interceptors.response.use(undefined, err => {
    const error = err.response;
    if (error.status === 401 || error.status === 403) {
        localStorage.removeItem('identity');
        window.location.assign('/account/login');
    }
})

export default instance
